<template>
  <div class="generic-list scroll-container spesa-self">
    <CategoryTitle
      :category="category"
      :class="{ 'hide-description': !spesaSelfActive }"
    />
    <div
      v-if="!spesaSelfActive"
      class="pb-6"
      v-html="category.metaData.category_info.FOOTER_DESCRIPTION"
    ></div>

    <div
      class="primary--text white darken-1 rounded-sm mt-6 py-2 px-6 d-flex justify-space-between"
    >
      <h3 class="d-flex align-center">
        <strong>{{
          spesaSelfActive
            ? $t("spesaSelf.deactivate")
            : $t("spesaSelf.activate")
        }}</strong>
      </h3>
      <v-switch
        color="success"
        @click="switchSpesaSelfActive"
        readonly
        :input-value="spesaSelfActive"
        :loading="spesaSelfActiveLoading"
      ></v-switch>
    </div>
  </div>
</template>
<style lang="scss">
.spesa-self {
  .hide-description {
    div:nth-child(2) {
      display: none;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import TigrosRegistrationService from "@/service/tigrosRegistrationService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SpesaSelf",
  data() {
    return {
      spesaSelfActiveLoading: false,
      promovar: "promovar_777777"
    };
  },
  mixins: [categoryMixin, reload],
  components: {
    CategoryTitle
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    selectedReceiptType() {
      return this.receiptType ? (this.receiptType == "home" ? 2 : 1) : null;
    },
    spesaSelfActive() {
      let crm = this.user.crmUserSegments.find(
        e => e.crmSegment.code == this.promovar
      );
      if (crm && crm.value) {
        return crm.value == 1;
      } else return false;
    }
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    async switchSpesaSelfActive() {
      this.spesaSelfActiveLoading = true;
      let response = await TigrosRegistrationService.updatePromovar(
        this.promovar,
        this.spesaSelfActive ? "0" : "1"
      );
      if (
        response.data &&
        response.data.value &&
        response.data.value.success === true
      ) {
        this.loadCart();
        global.EventBus.$emit("updateDashboard");
      }
      this.spesaSelfActiveLoading = false;
    }
  }
};
</script>
